import { FC, ReactNode, useCallback } from 'react';

import { Accept, useDropzone } from 'react-dropzone';

import { MAX_ASSET_FILE_SIZE } from '@constants/gereralConstants';
import { RejectedFile } from '@pages/home-page/types';
import { handleFileRejection } from '@pages/home-page/utils';

interface DropzoneProps {
  accept?: Accept;
  children?: ReactNode;
  maxSize?: number;
  multiple?: boolean;
  onFilesReject?: (rejectedFiles: RejectedFile[]) => void;
  onFilesDrop?(files: File[]): void;
}

const Dropzone: FC<DropzoneProps> = ({
  accept,
  children,
  maxSize = MAX_ASSET_FILE_SIZE,
  multiple = true,
  onFilesDrop = () => null,
  onFilesReject,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => onFilesDrop(acceptedFiles),
    [onFilesDrop],
  );

  const onDropRejected = useCallback(
    (fileRejections: RejectedFile[]) => {
      handleFileRejection(fileRejections, onFilesReject);
    },
    [onFilesReject],
  );
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    multiple,
    maxSize,
    onDrop,
    onDropRejected,
    noDragEventsBubbling: true,
  });

  return (
    <div {...getRootProps()}>
      {children}
      <input {...getInputProps()} />
    </div>
  );
};

export default Dropzone;
