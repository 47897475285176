import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { AngleBack } from '@assets/icons';
import { PermissionAccess, Spinner } from '@components/index';
import { GENERAL_CONSTANTS } from '@constants/gereralConstants';
import { UserPermissions } from '@enums/user-permissions';
import RoutesPath from '@routes/constants';
import { isSubscriptionAdmin } from '@store/atoms/userPermissions';

import WorkspaceTable from './components/WorkspaceTable';
import { useListWorkspace } from './hooks/use-list-workspace';

const Workspace = () => {
  const isSubscriptionAdminUser = useRecoilValue(isSubscriptionAdmin);

  const { workspaceList, isLoading } = useListWorkspace();

  return (
    <PermissionAccess
      permission={UserPermissions.VIEW_WORKSPACES}
      AllowedContent={
        <div className="w-full flex flex-col px-6">
          <div className="flex flex-row gap-2 items-center w-full h-[122px] py-12 px-11">
            <Link to={RoutesPath.BRAND_LIBRARY}>
              <AngleBack className="cursor-pointer" />
            </Link>
            <div className="text-2xl font-semibold">
              {' '}
              {GENERAL_CONSTANTS.Workspaces}
            </div>
          </div>
          {isLoading ? (
            <div className="relative pb-2 h-10">
              <div className="absolute inset-0 flex flex-col items-center justify-center">
                <Spinner />
              </div>
            </div>
          ) : (
            <WorkspaceTable workspaces={workspaceList} />
          )}
        </div>
      }
      redirectIfNoAccess
      forceRedirectToNoAccess={!isSubscriptionAdminUser}
    />
  );
};

export default Workspace;
