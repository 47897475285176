import { useEffect, useState } from 'react';

import { ListboxButton } from '@headlessui/react';
import { isEmpty, isNil, uniqBy } from 'lodash';
import { Link } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Add, AngleBack, AngleForward } from '@assets/icons';
import { LockedButton, PermissionAccess, Spinner } from '@components/index';
import { AlertType } from '@enums/alert';
import { UserPermissions } from '@enums/user-permissions';
import { showAlert } from '@lib/alert/alert-manager';
import RoutesPath from '@routes/constants';
import { loaderState } from '@store/atoms/loader-state';
import { userProfile } from '@store/atoms/user';
import { isSubscriptionAdmin } from '@store/atoms/userPermissions';
import { workspace } from '@store/atoms/workspace';

import AddWorkspaceModal from './AddWorkspaceModal';
import SelectWorkspaceDropdown from './SelectWorkspaceDropdown';
import { useCreateWorkspace } from '../hooks/use-create-workspace';
import { useListWorkspaces } from '../hooks/use-list-workspaces';
import { useSelectWorkspace } from '../hooks/use-select-workspace';
import { CreateWorkspaceData, Workspace } from '../types';

export const SwitchClientRefComponent = ({
  label,
  isOpen,
}: {
  label: string;
  isOpen: boolean;
}) => {
  const hasSpaces = label.trim().includes(' ');

  return (
    <div className="group flex justify-between items-center h-auto w-full rounded-lg cursor-pointer focus:outline-none text-black pr-3">
      <div className="flex-grow">
        <div className="flex items-center space-x-2 justify-start font-semibold mr-3">
          <Link to={RoutesPath.BRAND_LIBRARY}>
            <span
              className={`cursor-pointer ${
                hasSpaces ? 'break-words' : 'break-all'
              }`}
            >
              {label}
            </span>
          </Link>
        </div>
      </div>
      <ListboxButton className="cursor-pointer focus:outline-none text-white bg-primary rounded-md p-1">
        {isOpen ? (
          <AngleBack className="h-3 w-3" />
        ) : (
          <AngleForward className="h-3 w-3" />
        )}
      </ListboxButton>
    </div>
  );
};

const SwitchWorkspaceForm = () => {
  const setBlockUi = useSetRecoilState(loaderState);

  const currentUser = useRecoilValue(userProfile);
  const currentWorkspace = useRecoilValue(workspace);
  const isSubscriptionAdminUser = useRecoilValue(isSubscriptionAdmin);

  const [workspaceOptions, setWorkspaceOptions] = useState<Workspace[]>([]);
  const [searchKeyword, setSearchKeyword] = useState<string | null>(null);
  const [activeParentWorkspaceId, setActiveParentWorkspaceId] = useState<
    number | null
  >(null);
  const [switchingWorkspace, setSwitchingWorkspace] =
    useState<Workspace | null>(null);
  const [openAddWorkspaceModal, setOpenAddWorkspaceModal] = useState(false);

  const {
    workspaceList,
    isLoading: isLoadingWorkspaces,
    hasNextPage,
    fetchNextPage,
  } = useListWorkspaces({
    isActive: true,
    searchKeyword: searchKeyword,
    parentClientId: activeParentWorkspaceId,
    isEnabled: isNil(switchingWorkspace),
  });

  const { mutateAsync: createWorkspace, isLoading: isCreatingWorkspace } =
    useCreateWorkspace();

  useSelectWorkspace({
    userId: currentUser?.id as number,
    clientUuid: switchingWorkspace?.uuid as string,
    enabled: !isNil(currentUser?.id) && !isNil(switchingWorkspace?.uuid),
    onSuccess: () => {
      window.location.href = RoutesPath.BRAND_LIBRARY;
    },
    onError: () => {
      setBlockUi(false);
    },
  });

  useEffect(
    function initClientOptionsList() {
      if (isNil(currentWorkspace)) {
        return;
      }

      if (isEmpty(searchKeyword) && isNil(activeParentWorkspaceId)) {
        // If empty search keyword, show current client in the beginning of client options
        // Use a copy of currentClient to prevent focus outline issue
        return setWorkspaceOptions(
          uniqBy([{ ...currentWorkspace }, ...workspaceList], 'id'),
        );
      }

      setWorkspaceOptions(workspaceList);
    },
    [workspaceList, currentWorkspace, searchKeyword, activeParentWorkspaceId],
  );

  const onWorkSpaceCreation = async (data: CreateWorkspaceData) => {
    const response = await createWorkspace(data);
    if (response?.id) {
      showAlert({
        message: `${data.name} is created`,
        type: AlertType.SUCCESS,
      });
      setOpenAddWorkspaceModal(false);
      setSwitchingWorkspace(response);
    }
  };

  if (isNil(currentWorkspace)) {
    return (
      <div className="group flex justify-between items-center h-8 w-full px-2 rounded-lg cursor-pointer focus:outline-none">
        <div className="flex w-10">
          <Spinner color="bg-darker" />
        </div>
        <AngleForward height={15} />
      </div>
    );
  }

  return (
    <div className="w-full">
      <SelectWorkspaceDropdown
        options={workspaceOptions}
        isLoadingOptions={isLoadingWorkspaces}
        hasMoreOptions={hasNextPage}
        fetchMoreOptions={fetchNextPage}
        selectedOption={currentWorkspace}
        onOptionSelect={option => {
          if (option.id === currentWorkspace?.id) return;
          setSwitchingWorkspace(option);
          setBlockUi(true);
        }}
        setSearchKeyword={setSearchKeyword}
        activeParentId={activeParentWorkspaceId}
        setActiveParentId={setActiveParentWorkspaceId}
        popperPlacement="right-start"
        PopperRefComponent={SwitchClientRefComponent}
        classOverride="min-w-[400px] max-h-[500px]"
        optionClassOverride="underline"
      >
        {isSubscriptionAdminUser && (
          <PermissionAccess
            permission={UserPermissions.CREATE_WORKSPACE}
            AllowedContent={
              <div
                className="flex flex-row gap-3 p-3 cursor-pointer border-t border-gray-6"
                onClick={() => setOpenAddWorkspaceModal(true)}
              >
                <Add className="text-darker" />
                <span className="text-darker text-base font-semibold">
                  Add new workspace
                </span>
              </div>
            }
            DeniedContent={
              <LockedButton
                buttonText="Add workspace"
                buttonClassname="w-full"
                LeftIconComponent={Add}
              />
            }
          />
        )}
      </SelectWorkspaceDropdown>
      {openAddWorkspaceModal && (
        <AddWorkspaceModal
          isOpen={openAddWorkspaceModal}
          onClose={() => setOpenAddWorkspaceModal(false)}
          onWorkspaceCreation={onWorkSpaceCreation}
          isCreatingWorkspace={isCreatingWorkspace}
        />
      )}
    </div>
  );
};

export default SwitchWorkspaceForm;
