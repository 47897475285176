import { FC, useRef, useState } from 'react';

import { debounce } from 'lodash';

import { Button, Input } from '@components/index';
import {
  CollapsibleItemsState,
  GroupedBrandData,
} from '@pages/home-page/types';

import AssetTable from './asset-table/AssetTable';
import BrandUploader from './BrandUploader';

interface BrandLibraryDropzoneProps {
  handleUpload?: (files: File[]) => void;
  groupedBrandData: GroupedBrandData;
}

const BrandLibraryDropzone: FC<BrandLibraryDropzoneProps> = ({
  handleUpload,
  groupedBrandData,
}) => {
  const [collapsibleItemsState, setCollapsibleItemsState] =
    useState<CollapsibleItemsState>({
      isCollapseAll: true, // Object is used instead of boolean to rerender state later even if prev state is same as new state value
    });

  const [searchKeyword, setSearchKeyword] = useState<string>('');

  const handleBrandLibrarySearch = useRef(
    debounce(setSearchKeyword, 500),
  ).current;

  return (
    <>
      <BrandUploader onUploadFiles={handleUpload} />
      <div className="!cursor-default pb-1 pt-5">
        <div className="flex justify-between">
          <div className="flex flex-row gap-2 items-center">
            <Button
              label="Expand All"
              variant="text"
              classOverride="!hover:underline !text-gray-3 !text-sm"
              onClick={() =>
                setCollapsibleItemsState({
                  isCollapseAll: false,
                })
              }
            />
            <span className="text-gray-3">|</span>
            <Button
              label="Collapse All"
              variant="text"
              classOverride="!hover:underline !text-gray-3 !text-sm"
              onClick={() =>
                setCollapsibleItemsState({
                  isCollapseAll: true,
                })
              }
            />
          </div>
          <div>
            <Input
              placeholder="Search brand library"
              onChange={e => handleBrandLibrarySearch(e.target.value)}
              customInputClass="!w-[300px] !border-gray-6"
              withSearchIcon
            />
          </div>
        </div>
      </div>
      <AssetTable
        searchKeyword={searchKeyword}
        collapsibleItemsState={collapsibleItemsState}
        groupedBrandData={groupedBrandData}
      />
    </>
  );
};

export default BrandLibraryDropzone;
