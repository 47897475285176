import { Slide, toast, ToastContainer } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import { Alert } from '@components/index';
import { AlertType } from '@enums/alert';

export const AlertsContainer = ToastContainer;

export type Options = {
  header?: string;
  message: string;
  type?: AlertType;
  className?: string;
};

export const showAlert = (options: Options) => {
  const { message, header, type = AlertType.SUCCESS, className = '' } = options;

  toast(<Alert type={type} message={message} header={header} />, {
    toastId: message,
    position: 'top-center',
    autoClose: 3000,
    hideProgressBar: true,
    transition: Slide,
    className: twMerge(
      'rounded-2xl min-w-[300px] pt-3 pr-5',
      type === AlertType.ERROR ? 'bg-red-5' : 'bg-green-2',
      className,
    ),
  });
};
