import { FC } from 'react';

import { CircleCheck, Error } from '@assets/icons';
import { AlertType } from '@enums/alert';

export interface AlertProps {
  type: AlertType;
  header?: string;
  message: string;
}

const Alert: FC<AlertProps> = ({
  type = AlertType.SUCCESS,
  header = '',
  message = '',
}) => {
  return (
    <div className="flex gap-4 px-4">
      {type === AlertType.ERROR && (
        <Error className="flex-none h-5 w-5 text-red-1" />
      )}
      {type === AlertType.SUCCESS && (
        <CircleCheck className="flex-none h-5 w-5 text-green-1" />
      )}
      <div className="flex flex-col gap-2">
        {header && <p className="text-sm font-bold text-black">{header}</p>}
        <p className="min-w-0 break-words text-gray-10 text-sm">{message}</p>
      </div>
    </div>
  );
};

export default Alert;
