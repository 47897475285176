import { FC, useState } from 'react';

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { usePopper } from 'react-popper';

import { AssetBank } from '@assets/icons';
import {
  Button,
  Dropzone,
  LockedButton,
  PermissionAccess,
} from '@components/index';
import { UserPermissions } from '@enums/user-permissions';
import { AllAcceptedFiles } from '@pages/home-page/enums';

import AssetIdInput from './AssetIdInput';

interface BrandUploaderProps {
  onUploadFiles?: (files: File[]) => void;
  showAssetIdInput?: boolean;
}

const BrandUploader: FC<BrandUploaderProps> = ({
  onUploadFiles,
  showAssetIdInput = true,
}) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
    ],
  });

  return (
    <div className="w-full h-fit py-8 border-dashed border rounded-2xl border-gray-4 flex px-4 items-center">
      {showAssetIdInput && (
        <PermissionAccess
          permission={UserPermissions.MANAGE_BRAND_LIBRARY}
          AllowedContent={
            <div className="flex flex-col w-full items-center justify-center gap-3">
              <Popover>
                {() => (
                  <>
                    <div>
                      <PopoverButton
                        ref={
                          setReferenceElement as unknown as (
                            instance: HTMLButtonElement | null,
                          ) => void
                        }
                        as="div"
                      >
                        <Button
                          label="The Asset Bank"
                          classOverride="p-3 border border-gray-5 !text-base !font-medium"
                          IconLeftComponent={AssetBank}
                        />
                      </PopoverButton>
                    </div>
                    <PopoverPanel
                      className="shadow-md border p-4 bg-white rounded-md max-h-[370px] flex flex-col z-10 gap-2 w-[400px]"
                      ref={
                        setPopperElement as (
                          instance: HTMLDivElement | null,
                        ) => void
                      }
                      style={styles.popper}
                      {...attributes.popper}
                    >
                      <PopoverPanel as="div" className="text-sm">
                        Enter Asset ID(s)
                      </PopoverPanel>
                      <AssetIdInput showLabel={false} />
                    </PopoverPanel>
                  </>
                )}
              </Popover>
              <div className="flex items-center">
                <Dropzone
                  onFilesDrop={onUploadFiles}
                  multiple
                  accept={AllAcceptedFiles}
                >
                  <span className="cursor-pointer text-base text-darkest font-bold">
                    Click here
                  </span>
                </Dropzone>
                <span className="ml-2 text-base">
                  to upload a file or drag and drop
                </span>
              </div>
            </div>
          }
          DeniedContent={
            <div className="flex flex-col w-full items-center justify-center gap-3">
              <LockedButton buttonText="The Asset Bank" buttonClassname="p-4" />
              <div className="flex items-center">
                <span className="text-base text-darkest">Click here</span>
                <span className="ml-2 text-base">
                  to upload a file or drag and drop
                </span>
              </div>
            </div>
          }
        />
      )}
      &nbsp;
    </div>
  );
};

export default BrandUploader;
