import { FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

import { Cross, Document } from '@assets/icons';
import { Modal, Spinner } from '@components/index';
import { RetrievedDocument } from '@hooks/types';
import { PreviewType } from '@pages/home-page/enums';

interface ChunkPreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  documentList: RetrievedDocument[];
}

const ChunkPreviewModal: FC<ChunkPreviewModalProps> = ({
  isOpen,
  onClose,
  documentList,
}) => {
  const [selectedDocument, setSelectedDocument] = useState<RetrievedDocument>(
    documentList[0],
  );
  const [activeTab, setActiveTab] = useState(PreviewType.DOCUMENT);
  const [isLoading, setIsLoading] = useState(true);

  const {
    file_name: fileName = '',
    page_number: pageNumber = null,
    page_content: pageContent = null,
    image_url: imageUrl = '',
    external_asset_id: externalAssetId = '',
  } = selectedDocument || {};

  useEffect(() => {
    setIsLoading(true);
  }, [imageUrl]);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} showCloseButton={false}>
      <div className="flex flex-row w-[70vw] h-[80vh]">
        <div className="flex flex-col border-r mb-2 w-[35%] overflow-auto">
          <div className="flex flex-row border-b  h-[55px] p-3 items-center text-md font-semibold">
            <span className="flex items-center mt-1 gap-2">
              <Document className="h-5 w-5" />
              Source document
            </span>
          </div>
          <div className="px-2 my-2">
            {documentList.map(fileData => {
              const selectedFileClassOverride =
                fileData.id === selectedDocument.id
                  ? 'bg-lighter rounded-md'
                  : '';

              return (
                <div
                  key={fileData.id}
                  className={twMerge(
                    classNames(
                      'text-sm cursor-pointer py-3 px-2 break-words',
                      selectedFileClassOverride,
                    ),
                  )}
                  onClick={() => setSelectedDocument(fileData)}
                >
                  {fileData.file_name}
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex flex-row justify-between items-center border-b h-[55px] p-3">
            <div className="flex flex-row justify-center items-center gap-2">
              <div className="text-md font-semibold break-all">{fileName}</div>
              {externalAssetId && (
                <div className="flex items-center text-sm text-blue-2 border-blue-3 bg-blue-4 rounded-xl p-1 font-semibold w-fit max-w-[200px] overflow-hidden whitespace-nowrap">
                  <span className="w-full truncate mt-0.5">
                    {`TAB ID: ${externalAssetId}`}
                  </span>
                </div>
              )}
            </div>
            <div className="flex gap-2 items-center">
              <div className="text-sm border p-1 rounded">
                {`Page no. ${pageNumber}`}
              </div>
              <Cross
                className="cursor-pointer h-6 w-6 text-gray-14"
                onPointerDown={(e: React.PointerEvent<Element>) => {
                  e.stopPropagation();
                  onClose();
                }}
              />
            </div>
          </div>
          <div className="flex items-center justify-center m-3">
            <div className="flex border border-gray-200 rounded-2xl overflow-hidden">
              <span
                onClick={() => setActiveTab(PreviewType.DOCUMENT)}
                className={`py-1 !w-32 cursor-pointer text-center ${
                  activeTab === PreviewType.DOCUMENT
                    ? 'bg-lighter border-lighter text-primary'
                    : 'bg-white'
                }`}
              >
                Document
              </span>
              <span
                onClick={() => setActiveTab(PreviewType.TEXT)}
                className={`py-1 !w-32 cursor-pointer text-center ${
                  activeTab === PreviewType.TEXT
                    ? 'bg-lighter border-lighter text-primary'
                    : 'bg-white'
                }`}
              >
                Text
              </span>
            </div>
          </div>
          <div className="p-4 text-sm overflow-auto">
            {activeTab === PreviewType.TEXT ? (
              pageContent?.split('\n').map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))
            ) : (
              <div className="flex flex-col w-full h-[calc(80vh-200px)] items-center overflow-auto my-2 relative">
                {isLoading && (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <Spinner />
                  </div>
                )}
                <img
                  src={imageUrl}
                  alt="Document image"
                  className={twMerge(
                    classNames(
                      'border shadow-lg overflow-auto h-full transition-opacity duration-400 ease-in-out object-contain',
                      { 'opacity-0': isLoading, 'opacity-100': !isLoading },
                    ),
                  )}
                  onLoad={handleImageLoad}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChunkPreviewModal;
