import { FC } from 'react';

import { Button, Modal } from '@components/index';

import { WORKSPACE_CONSTANTS } from '../constants';
import { WorkspaceAction } from '../enums';

interface WorkspaceActionConfirmationModalProps {
  appearance: WorkspaceAction;
  isOpen: boolean;
  isLoading: boolean;
  onCancelClick(): void;
  onContinueClick(): void;
}

export const WorkspaceActionConfirmationModal: FC<
  WorkspaceActionConfirmationModalProps
> = ({ appearance, isOpen, isLoading, onCancelClick, onContinueClick }) => {
  return (
    <Modal isOpen={isOpen} onClose={onCancelClick}>
      <div className="flex flex-col m-4 px-4 py-2 w-[600px]">
        <h3 className="font-medium text-base">
          Continue to
          {appearance === WorkspaceAction.DEACTIVATE
            ? ' deactivate?'
            : ' activate?'}
        </h3>
        <div className="mt-2 mb-5 text-sm text-dark-2">
          {appearance === WorkspaceAction.DEACTIVATE
            ? WORKSPACE_CONSTANTS.deactivateMessage
            : WORKSPACE_CONSTANTS.activateMessage}
        </div>
        <div className="my-3 space-x-2 flex">
          <Button
            variant="outlined"
            label={WORKSPACE_CONSTANTS.Cancel}
            isDisabled={isLoading}
            onClick={onCancelClick}
            classOverride="font-bold !w-1/2 border-gray-6"
          />
          <Button
            variant="contained"
            label={
              appearance === WorkspaceAction.DEACTIVATE
                ? 'Deactivate'
                : 'Activate'
            }
            isDisabled={isLoading}
            isLoading={isLoading}
            onClick={onContinueClick}
            classOverride="font-bold !w-1/2"
          />
        </div>
      </div>
    </Modal>
  );
};
