import { useMutation, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';

import { HttpMethod } from '@api/enums';
import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { QUERY_KEYS } from '@constants/query-keys';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';
import { workspace } from '@store/atoms/workspace';

import { GetUsersResponse } from '../types';

export const useUpdateRole = () => {
  const currentWorkspace = useRecoilValue(workspace);

  const client = createHttpClient();
  const queryClient = useQueryClient();

  const queryKey = QUERY_KEYS.USER.USER_LIST;

  const mutation = useMutation(
    ({
      roleId,
      userId,
    }: {
      roleId: number;
      userId: number;
      onRoleUpdateError: () => void;
    }) =>
      client({
        method: HttpMethod.PATCH,
        url: END_POINTS.ROLE.UPDATE_USER_ROLE(userId, currentWorkspace.id),
        data: { roleId },
      }),
    {
      onSuccess: (_, { roleId, userId }) => {
        showAlert({
          type: AlertType.SUCCESS,
          message: 'Role updated successfully',
        });

        const cache = queryClient.getQueryData<GetUsersResponse>(queryKey);

        const updatedCache = cache?.map(user => {
          if (user.userId === userId) {
            return { ...user, roleId };
          }

          return user;
        });
        queryClient.setQueryData(queryKey, updatedCache);
      },
      onError: (error: Error, { onRoleUpdateError }) => {
        onRoleUpdateError();
        showAlert({
          type: AlertType.ERROR,
          message: `${error?.message || 'Failed to update role'}`,
        });
      },
    },
  );

  return mutation;
};
