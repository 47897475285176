import { DocIcon, DocxIcon, PdfIcon, PptIcon, PptxIcon } from '@assets/icons';

import {
  AssetType,
  ColorCodeCategory,
  KnowledgeExtractionStatus,
  Language,
  RawFileTypes,
} from './enums';
import { RGBAColorType } from './types';

export const ASSET_TABLE_HEADERS = [
  { name: 'Name/Details', classOverride: 'w-[38%] pl-5' },
  { name: 'Workspace', classOverride: 'w-[20%] pl-10' },
  { name: 'Created by', classOverride: 'w-[15%] pl-0' },
  { name: 'Created on', classOverride: 'w-[15%] pl-0' },
];

export const ASSET_INFO = {
  [AssetType.RAW_FILE]: {
    name: 'Documents',
    fileTypes: ['pptx', 'ppt', 'doc', 'docx', 'pdf'],
    accept: {
      'application/vnd.ms-powerpoint': ['.pptx', '.ppt'],
      'application/msword': ['.doc', '.docx'],
      'application/pdf': ['.pdf'],
    },
    tooltipText:
      'The documents are stacked as per hierarchy. By default the global document will be given 1st priority. The priority can be changed by dragging the order.',
  },
  [AssetType.LOGO]: {
    name: 'Logos',
    fileTypes: ['png', 'jpg'],
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
    },
  },
  [AssetType.FONT]: {
    name: 'Fonts',
    fileTypes: ['ttf', 'otf'],
    accept: {
      'font/ttf': ['.ttf'],
      'font/otf': ['.otf'],
    },
  },
  [AssetType.COLORS]: {
    name: 'Colors',
    fileTypes: ['hex', 'rgb'],
    accept: {
      'text/hex': ['.hex'],
      'text/rgb': ['.rgb'],
    },
  },
  [AssetType.PRODUCT]: {
    name: 'Images',
    fileTypes: ['png', 'jpg', 'jpeg', 'webp', 'tif', 'tiff'],
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/webp': ['.webp'],
      'image/tiff': ['.tif', '.tiff'],
    },
  },
  [AssetType.VIDEO]: {
    name: 'Videos',
    fileTypes: ['mp4', 'mov', 'mpg', 'mpeg', 'avi', 'webm', 'wmv'],
    accept: {
      'video/mp4': ['.mp4'],
      'video/quicktime': ['.mov'],
      'video/mpeg': ['.mpg', '.mpeg'],
      'video/x-msvideo': ['.avi'],
      'video/webm': ['.webm'],
      'video/x-ms-wmv': ['.wmv'],
    },
  },
  [AssetType.KNOWLEDGE]: {
    name: 'Attributes',
    fileTypes: [],
    accept: {},
    tooltipText:
      'The attributes are stacked as per hierarchy. By default the global document will be given 1st priority. The priority can be changed by dragging the order.',
  },
};

export const UNSUPPORTED_FILE_EXT_ERROR =
  'The file you uploaded is not supported. Please upload a valid file format.';

export const FILE_SIZE_EXCEEDED_MESSAGE =
  'File size must be 500 MB or less. Consider compressing or selecting a smaller file.';

export const DEFAULT_LANGUAGE = { id: Language.ENGLISH, label: 'English' };

export const LANGUAGE_OPTIONS = [
  DEFAULT_LANGUAGE,
  { id: Language.ARABIC, label: 'Arabic' },
  { id: Language.BENGALI, label: 'Bengali' },
  { id: Language.BOSNIAN, label: 'Bosnian' },
  { id: Language.CATALAN, label: 'Catalan' },
  { id: Language.CHINESE, label: 'Chinese (Simplified)' },
  { id: Language.TRADITIONAL_CHINESE, label: 'Chinese (Traditional)' },
  { id: Language.CROATIAN, label: 'Croatian' },
  { id: Language.CZECH, label: 'Czech' },
  { id: Language.DANISH, label: 'Danish' },
  { id: Language.DUTCH, label: 'Dutch' },
  { id: Language.FINNISH, label: 'Finnish' },
  { id: Language.FRENCH, label: 'French' },
  { id: Language.GERMAN, label: 'German' },
  { id: Language.GREEK, label: 'Greek' },
  { id: Language.GUJARATI, label: 'Gujarati' },
  { id: Language.HEBREW, label: 'Hebrew' },
  { id: Language.HINDI, label: 'Hindi' },
  { id: Language.HUNGARIAN, label: 'Hungarian' },
  { id: Language.INDONESIA, label: 'Indonesian' },
  { id: Language.ITALIAN, label: 'Italian' },
  { id: Language.JAPANESE, label: 'Japanese' },
  { id: Language.KANNADA, label: 'Kannada' },
  { id: Language.KOREAN, label: 'Korean' },
  { id: Language.MALAY, label: 'Malay' },
  { id: Language.MALAYALAM, label: 'Malayalam' },
  { id: Language.MARATHI, label: 'Marathi' },
  { id: Language.NORWEGIAN, label: 'Norwegian' },
  { id: Language.POLISH, label: 'Polish' },
  { id: Language.PORTUGUESE, label: 'Portuguese' },
  { id: Language.PUNJABI, label: 'Punjabi' },
  { id: Language.ROMANIAN, label: 'Romanian' },
  { id: Language.RUSSIAN, label: 'Russian' },
  { id: Language.SERBIAN, label: 'Serbian' },
  { id: Language.SLOVAKIAN, label: 'Slovakian' },
  { id: Language.SLOVENIAN, label: 'Slovenian' },
  { id: Language.SPANISH, label: 'Spanish' },
  { id: Language.SWEDISH, label: 'Swedish' },
  { id: Language.TAGALOG, label: 'Tagalog' },
  { id: Language.TAMIL, label: 'Tamil' },
  { id: Language.TELUGU, label: 'Telugu' },
  { id: Language.THAI, label: 'Thai' },
  { id: Language.TURKISH, label: 'Turkish' },
  { id: Language.UKRAINIAN, label: 'Ukrainian' },
  { id: Language.VIETNAMESE, label: 'Vietnamese' },
];

export const MAX_ATTRIBUTE_DETAILS_CHARACTER_LENGTH = 2500;

export const PRESET_COLORS = [
  '#5395E9',
  '#83BE5F',
  '#F5CD6E',
  '#EE9248',
  '#DB555A',
  '#BF2A68',
  '#951FB3',
  '#C9DFF8',
  '#D9EBCE',
  '#FBF0D2',
  '#F9DEC5',
  '#F2CACD',
  '#E8B7D1',
  '#DCB6E6',
  '#000000',
  '#FFFFFF',
];

export const COLOR_SELECT_OPTIONS = [
  {
    id: ColorCodeCategory.HEX,
    label: 'HEX',
  },
  {
    id: ColorCodeCategory.RGB,
    label: 'RGB',
  },
];

export const RGBA_VALUE = ['r', 'g', 'b', 'a'];

export const DEFAULT_RGBA_COLOR: RGBAColorType = {
  r: 237,
  g: 215,
  b: 215,
  a: 255,
};

export const DEFAULT_HEX_COLOR_FOR_PICKER = '#EDD7D7';

export const KnowledgeExtractionStatusMap: Record<number, string> = {
  [KnowledgeExtractionStatus.PENDING]: 'Processing',
  [KnowledgeExtractionStatus.PROGRESS]: 'Processing',
  [KnowledgeExtractionStatus.FAILURE]: 'Failed',
  [KnowledgeExtractionStatus.SUCCESS]: 'Completed',
  [KnowledgeExtractionStatus.BINNED]: 'Binned',
  [KnowledgeExtractionStatus.TIMEOUT]: 'Timeout',
  [KnowledgeExtractionStatus.PARTIALSUCCESS]: 'Partial Success',
  [KnowledgeExtractionStatus.VALIDATING]: 'Validating',
  [KnowledgeExtractionStatus.INVALID]: 'Invalid',
};

export const EXTRACTION_COMPLETED_ALERT = {
  [KnowledgeExtractionStatus.SUCCESS]:
    'Successfully imported brand knowledge from the uploaded document',
  [KnowledgeExtractionStatus.FAILURE]:
    'Failed to import brand knowledge from the uploaded document',
};

export const iconMap: {
  [key in RawFileTypes]: React.FC<React.SVGProps<SVGSVGElement>>;
} = {
  [RawFileTypes.PDF]: PdfIcon,
  [RawFileTypes.DOCX]: DocxIcon,
  [RawFileTypes.DOC]: DocIcon,
  [RawFileTypes.PPT]: PptIcon,
  [RawFileTypes.PPTX]: PptxIcon,
};

export const MAX_ZOOM_LEVEL = 1.8;

export const MIN_ZOOM_LEVEL = 0.5;

export const ZOOM_STEP = 0.1;
