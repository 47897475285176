export const Atoms = {
  loaderState: 'loaderState',
  userProfile: 'userProfile',
  workspace: 'currentWorkspace',
  chatList: 'chatList',
  chatLoaderState: 'chatLoader',
  selectedChatSuggestion: 'selectedChatSuggestion',
  chatLanguage: 'chatLanguage',
  chatSessionId: 'chatSessionId',
  uploadFiles: 'uploadFiles',
  knowledgeAttributes: 'knowledgeAttributes',
  documentList: 'documentList',
  customer: 'customer',
  userPermissions: 'userPermissions',
  isSubscriptionAdmin: 'isSubscriptionAdmin',
};
