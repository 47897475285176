import React, { ReactNode, useEffect, useRef, useState } from 'react';

interface StackedContainerProps {
  renderNode: ReactNode;
  isStackExpanded?: boolean;
}

const stackedRows = [
  { index: 0, className: 'absolute top-0 left-0 right-0 z-10' },
  { index: 1, className: 'absolute top-5 left-6 right-6 z-8' },
  { index: 2, className: 'absolute top-2.5 left-3 right-3 z-5' },
];

const StackedContainer: React.FC<StackedContainerProps> = ({
  renderNode,
  isStackExpanded,
}) => {
  const [height, setHeight] = useState('auto');
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      setHeight(`${containerRef?.current?.scrollHeight}px`);
    }
  }, [containerRef.current, isStackExpanded]);

  return (
    <div ref={containerRef} style={{ height }} className="relative">
      {stackedRows.map(({ index, className }) => (
        <div key={index} className={className}>
          {renderNode}
        </div>
      ))}
    </div>
  );
};

export default StackedContainer;
