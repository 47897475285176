import { FC, useState } from 'react';

import {
  DocIcon,
  DocxIcon,
  EllipsisGrid,
  PdfIcon,
  PptIcon,
  PptxIcon,
} from '@assets/icons';
import { MoreOptions } from '@components/index';
import { KnowledgeExtractionStatusMap } from '@pages/home-page/constants';
import {
  AssetType,
  KnowledgeExtractionStatus,
  RawFileTypes,
} from '@pages/home-page/enums';
import { DataItem } from '@pages/home-page/types';
import { getFileExt } from '@utils/file';

import DocumentPreviewModal from './DocumentPreview';

interface DocumentRowProps {
  item: DataItem;
  handleDelete: (id: number, assetType: AssetType) => void;
  showDragHandle?: boolean;
  isGrouped?: boolean;
  isStacked?: boolean;
}

const iconMap: {
  [key in RawFileTypes]: React.FC<React.SVGProps<SVGSVGElement>>;
} = {
  [RawFileTypes.PDF]: PdfIcon,
  [RawFileTypes.DOCX]: DocxIcon,
  [RawFileTypes.DOC]: DocIcon,
  [RawFileTypes.PPT]: PptIcon,
  [RawFileTypes.PPTX]: PptxIcon,
};

const getKnowledgeStatusStyles = (status: number) => {
  switch (status) {
    case 1:
    case 2:
      return 'bg-yellow-5 text-yellow-3';
    case 3:
      return 'bg-red-2 text-red-3';
    case 4:
      return 'bg-green-2 text-green-3';
    default:
      return 'bg-gray-2 text-gray-3';
  }
};

const DocumentRow: FC<DocumentRowProps> = ({
  item,
  handleDelete,
  showDragHandle = true,
  isGrouped = false,
  isStacked = false,
}) => {
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  const fileExt = getFileExt(item.name).toLowerCase() as RawFileTypes;
  const FileIcon = iconMap[fileExt] || DocIcon;

  const showDelete = ![
    KnowledgeExtractionStatus.PENDING,
    KnowledgeExtractionStatus.PROGRESS,
  ].includes(item.knowledgeExtractionStatus as number);

  const showPreview =
    !!item.imageUrls?.length &&
    (item.knowledgeExtractionStatus as number) ===
      KnowledgeExtractionStatus.SUCCESS;

  return (
    <>
      <div
        className={`flex flex-row items-start border rounded py-2 shadow-sm px-2 my-1 ${isStacked ? 'bg-lightest border-gray-2' : 'bg-white border-gray-2'}`}
      >
        <div className="w-[45%] flex flex-row gap-4 items-center pr-3">
          {showDragHandle && <EllipsisGrid className="h-4 w-4 flex-shrink-0" />}
          <FileIcon className="h-7 w-7 flex-shrink-0" />
          <div className="flex flex-col gap-1">
            <div
              className="flex-grow text-sm break-words overflow-hidden text-ellipsis whitespace-normal text-justify"
              style={{ wordBreak: 'break-word' }}
            >
              {item.name}
            </div>
            {item.knowledgeExtractionStatus && (
              <div
                className={`${getKnowledgeStatusStyles(item.knowledgeExtractionStatus)} rounded-xl px-2 py-1 w-fit text-xs`}
              >
                {
                  KnowledgeExtractionStatusMap[
                    item.knowledgeExtractionStatus as number
                  ]
                }
              </div>
            )}
          </div>
        </div>
        <div
          className={`w-[20%] flex-grow text-sm overflow-hidden text-ellipsis whitespace-normal ${!isGrouped ? 'pl-4' : 'pl-1'}`}
          style={{ wordBreak: 'break-word' }}
        >
          {item.parentClientName}
        </div>
        <div
          className={`w-[15%] flex-grow text-sm overflow-hidden text-ellipsis whitespace-normal ${!isGrouped ? 'pl-5' : 'pl-3'}`}
          style={{ wordBreak: 'break-word' }}
        >
          {item.createdBy}
        </div>

        <div className="w-[15%] text-sm pl-10">{item.createdAt || '-'}</div>
        <div
          className={`flex justify-end w-[5%] ${!isGrouped ? 'pr-2' : 'pr-0'} `}
          onPointerDown={e => e.stopPropagation()}
        >
          {(showDelete || showPreview) && (
            <MoreOptions
              classOverride="!items-start"
              {...(showDelete && {
                onClickDelete: () => handleDelete(item.id, item.assetType),
              })}
              {...(showPreview && {
                onClickPreview: () => setOpenPreviewModal(true),
              })}
            />
          )}
        </div>
      </div>
      {openPreviewModal && (
        <DocumentPreviewModal
          fileName={item.name}
          isOpen={openPreviewModal}
          onClose={() => setOpenPreviewModal(false)}
          imageUrls={item?.imageUrls as string[]}
          externalAssetId={item?.externalAssetId}
        />
      )}
    </>
  );
};

export default DocumentRow;
