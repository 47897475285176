import { FC, useState } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { AngleDown, AngleUp, EllipsisGrid } from '@assets/icons';
import { StackedContainer } from '@components/index';
import { AssetType } from '@pages/home-page/enums';
import { DataItem } from '@pages/home-page/types';

import DocumentRow from './document-items/DocumentRow';
import AttributeRow from './knowledge-attributes/AttributeRow';

interface DraggableAssetRowProps {
  item: DataItem | DataItem[];
  handleDelete: (id: number, assetType: AssetType) => void;
  workspaceId?: number;
  handleEdit?: (item: DataItem, assetType: AssetType) => void;
  isDraggable?: boolean;
  isGrouped?: boolean;
  isDocumentRow?: boolean;
}

const DraggableAssetRow: FC<DraggableAssetRowProps> = ({
  item,
  handleDelete,
  handleEdit,
  isDraggable = true,
  isGrouped = false,
  workspaceId,
  isDocumentRow = true,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const items = Array.isArray(item) ? item : [item];
  const firstItem = items[0];
  const ExpandIcon = isExpanded ? AngleUp : AngleDown;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: firstItem.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <tr
      {...(isDraggable && {
        ref: setNodeRef,
        style,
        ...attributes,
        ...listeners,
      })}
      className={isDraggable ? 'cursor-pointer bg-white' : ''}
    >
      <td className="px-2" colSpan={4}>
        {!isGrouped ? (
          isDocumentRow ? (
            <DocumentRow
              item={firstItem}
              handleDelete={handleDelete}
              showDragHandle={isDraggable}
            />
          ) : (
            <AttributeRow
              item={firstItem}
              isDraggable={isDraggable}
              isGrouped={false}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              workspaceId={workspaceId as number}
            />
          )
        ) : (
          <>
            <div className="flex justify-between px-2 my-2">
              <div className="text-sm font-semibold">
                {isDocumentRow ? 'Global Documents' : 'Global Attributes'}
              </div>
              {items?.length > 1 && (
                <div
                  className="flex text-sm font-semibold border rounded-2xl px-2 py-1 cursor-pointer"
                  onPointerDown={e => {
                    e.stopPropagation();
                    setIsExpanded(prev => !prev);
                  }}
                >
                  {isExpanded ? 'Show less' : 'Show more'}
                  <ExpandIcon className="ml-2" />
                </div>
              )}
            </div>
            {isExpanded ? (
              <div className="flex bg-lightest py-2 rounded mb-2 border border-gray-6">
                <div className="flex flex-col justify-center">
                  {isDraggable && (
                    <EllipsisGrid className="h-4 w-4 flex-shrink-0 ml-2" />
                  )}
                </div>
                <div className="flex-1 px-2 py-1">
                  {items.map(nestedItem =>
                    isDocumentRow ? (
                      <DocumentRow
                        key={nestedItem.id}
                        isGrouped={isGrouped}
                        item={nestedItem}
                        showDragHandle={false}
                        handleDelete={handleDelete}
                      />
                    ) : (
                      <AttributeRow
                        key={nestedItem.id}
                        item={nestedItem}
                        isGrouped={true}
                        isDraggable={false}
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                        workspaceId={workspaceId as number}
                      />
                    ),
                  )}
                </div>
              </div>
            ) : (
              <StackedContainer
                renderNode={
                  isDocumentRow ? (
                    <DocumentRow
                      item={firstItem}
                      showDragHandle={true}
                      handleDelete={handleDelete}
                      isStacked
                    />
                  ) : (
                    <AttributeRow
                      item={firstItem}
                      isDraggable={true}
                      isGrouped={true}
                      handleDelete={handleDelete}
                      handleEdit={handleEdit}
                      workspaceId={workspaceId as number}
                      isStacked
                    />
                  )
                }
                isStackExpanded={isExpanded}
              />
            )}
          </>
        )}
      </td>
    </tr>
  );
};

export default DraggableAssetRow;
