import {
  PermissionAccess,
  StyledDropzone,
  UploadProgress,
} from '@components/index';
import { AlertType } from '@enums/alert';
import { UserPermissions } from '@enums/user-permissions';
import { showAlert } from '@lib/alert/alert-manager';
import {
  ASSET_INFO,
  EXTRACTION_COMPLETED_ALERT,
} from '@pages/home-page/constants';
import {
  AllAcceptedFiles,
  AssetType,
  KnowledgeExtractionStatus,
} from '@pages/home-page/enums';
import { useBrandLibrary } from '@pages/home-page/hooks/use-brand-library';
import { useExtractBrandKnowledge } from '@pages/home-page/hooks/use-extract-brandknowledge';
import { useGetGroupedBrandData } from '@pages/home-page/hooks/use-get-grouped-brand-data';
import { getFileExt } from '@utils/file';

import BrandLibraryDropzone from './BrandLibraryDropZone';

const BrandLibrary = () => {
  const groupedBrandData = useGetGroupedBrandData();

  const { handleUploadFiles } = useBrandLibrary();

  useExtractBrandKnowledge({
    onExtractionComplete: (
      status:
        | KnowledgeExtractionStatus.FAILURE
        | KnowledgeExtractionStatus.SUCCESS,
      message?: string,
    ) => {
      const isExtractionSuccess = status === KnowledgeExtractionStatus.SUCCESS;
      const type = isExtractionSuccess ? AlertType.SUCCESS : AlertType.ERROR;
      const alertMessage = message ?? EXTRACTION_COMPLETED_ALERT[status];
      showAlert({ type, message: alertMessage });
    },
  });

  const handleUpload = (files: File[]): void => {
    if (!files?.length) return;

    files.forEach(file => {
      const fileType = getFileExt(file.name).toLowerCase();

      let assetType: AssetType | null = null;

      if (
        ASSET_INFO[AssetType.PRODUCT].fileTypes
          .filter(file => file !== 'png')
          .includes(fileType)
      ) {
        assetType = AssetType.PRODUCT;
      } else if (fileType === 'png') {
        assetType = AssetType.LOGO;
      } else {
        assetType =
          (Object.keys(ASSET_INFO).find(type =>
            ASSET_INFO[type as AssetType]?.fileTypes.includes(fileType),
          ) as AssetType) || null;
      }

      handleUploadFiles([file], assetType as AssetType);
    });
  };

  return (
    <div className="flex flex-col w-full h-screen border-r border-gray-6">
      <div className="h-[65px] flex justify-between items-center p-4 border-b border-gray-6">
        <span className="text-2xl font-semibold">Brand Library</span>
      </div>
      <div className="h-[calc(100vh-65px)] flex flex-col p-5 overflow-hidden">
        <PermissionAccess
          permission={UserPermissions.MANAGE_BRAND_LIBRARY}
          AllowedContent={
            <StyledDropzone
              onFilesDrop={handleUpload}
              accept={AllAcceptedFiles}
            >
              <BrandLibraryDropzone
                handleUpload={handleUpload}
                groupedBrandData={groupedBrandData}
              />
            </StyledDropzone>
          }
          DeniedContent={
            <BrandLibraryDropzone groupedBrandData={groupedBrandData} />
          }
        />
      </div>
      <UploadProgress />
    </div>
  );
};

export default BrandLibrary;
