import React from 'react';

import { CheckBoxOff, CheckBoxOn } from '@assets/icons';

interface CheckboxProps {
  label?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  onChange,
  className = '',
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <label className={`flex cursor-pointer items-center gap-1 ${className}`}>
      {checked ? (
        <CheckBoxOn
          className="text-green-4 flex-shrink-0"
          onClick={handleChange}
        />
      ) : (
        <CheckBoxOff
          className="text-gray-5 flex-shrink-0"
          onClick={handleChange}
        />
      )}
      {label && <span className="text-black text-sm break-all">{label}</span>}
    </label>
  );
};

export default Checkbox;
