import { FC, ReactNode, useEffect } from 'react';

import RoutesPath from '@routes/constants';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userPermissions } from '@store/atoms/userPermissions';
import { UserPermissions } from '@enums/user-permissions';

interface PermissionAcccessProps {
  AllowedContent: ReactNode;
  DeniedContent?: ReactNode;
  permission: UserPermissions;
  redirectIfNoAccess?: boolean;
  forceRedirectToNoAccess?: boolean;
}

const PermissionAccess: FC<PermissionAcccessProps> = ({
  AllowedContent,
  DeniedContent,
  permission,
  redirectIfNoAccess,
  forceRedirectToNoAccess = false,
}) => {
  const navigate = useNavigate();

  const permissions = useRecoilValue(userPermissions);
  const hasAccess = permissions?.includes(permission);

  useEffect(() => {
    if (forceRedirectToNoAccess || (!hasAccess && redirectIfNoAccess))
      navigate(RoutesPath.NO_ACCESS);
  }, [redirectIfNoAccess, hasAccess, forceRedirectToNoAccess]);

  return <>{hasAccess ? AllowedContent : DeniedContent}</>;
};

export default PermissionAccess;
