import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { ScreenBlockUi, SideNav } from '@components/index';
import { loaderState } from '@store/atoms/loader-state';

type MainLayoutProps = {
  children: React.ReactNode;
};

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const isBlocking = useRecoilValue(loaderState);

  return (
    <ScreenBlockUi isBlocking={isBlocking}>
      <div className="flex flex-row">
        <SideNav />
        <div className="flex w-[calc(100vw-189px)]">{children}</div>
      </div>
    </ScreenBlockUi>
  );
};

export default MainLayout;
