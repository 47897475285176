import { useMutation } from 'react-query';
import { useRecoilValue } from 'recoil';

import { HttpMethod } from '@api/enums';
import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';
import { workspace } from '@store/atoms/workspace';

import { ImportAssetIdsBody } from '../types';

export const useImportAssetIds = () => {
  const client = createHttpClient();

  const currentWorkspace = useRecoilValue(workspace);

  const mutation = useMutation(
    (payload: ImportAssetIdsBody) =>
      client({
        method: HttpMethod.POST,
        url: END_POINTS.BRAND_LIBRARY.IMPORT_ASSET_IDS(currentWorkspace.id),
        data: payload,
      }),
    {
      retry: 0,
      onError: (error: Error) => {
        showAlert({
          type: AlertType.ERROR,
          header: 'Something went wrong',
          message: `${error?.message || 'request failed'}`,
        });
      },
      onSuccess: (_, { tabIds }) => {
        showAlert({
          type: AlertType.SUCCESS,
          message: `Asset ID${tabIds?.length == 1 ? ' was' : 's were'} sent. Please refresh the page in a few minutes to see ${tabIds?.length == 1 ? 'it' : 'them'}.`,
        });
      },
    },
  );

  return mutation;
};
