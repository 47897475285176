import { useMutation } from 'react-query';

import { HttpMethod } from '@api/enums';
import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';

export const useLockAction = (isAttributeLocked: boolean) => {
  const client = createHttpClient();

  const mutation = useMutation(
    ({ attributeId }: { attributeId: number; onError: () => void }) =>
      client({
        url: isAttributeLocked
          ? END_POINTS.BRAND_LIBRARY.UNLOCK_ATTRIBUTE(attributeId)
          : END_POINTS.BRAND_LIBRARY.LOCK_ATTRIBUTE(attributeId),
        method: HttpMethod.PATCH,
      }),
    {
      onError: (error: Error, variables) => {
        showAlert({
          type: AlertType.ERROR,
          header: `Failed to ${isAttributeLocked ? 'unlock' : 'lock'} attribute`,
          message: `${error?.message || 'Request failed'}`,
        });
        if (variables?.onError) {
          variables.onError();
        }
      },
      onSuccess: () => {
        showAlert({
          type: AlertType.SUCCESS,
          message: `Attribute ${isAttributeLocked ? 'unlocked' : 'locked'} successfully!`,
        });
      },
    },
  );

  return mutation;
};
