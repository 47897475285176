export const WORKSPACE_TABLE_HEADERS = [
  { name: 'Workspace Name', classOverride: 'w-[calc(100%-39%)]' },
  { name: 'Creator', classOverride: 'w-[15%]' },
  { name: 'Date Created', classOverride: 'w-[15%]' },
  { name: 'No. of users', classOverride: 'w-[10%]' },
  { name: 'Action', classOverride: 'w-[10%]' },
];

export const WORKSPACE_CONSTANTS = {
  deactivateMessage:
    'Are you sure you want to deactivate this item? You can reactivate it later if needed.',
  activateMessage:
    'Are you sure you want to activate this item? This will make it available for use.',
  Cancel: ' No, Cancel',
};
