import {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { Transition } from '@headlessui/react';
import { debounce } from 'lodash';

import { Cross, FilterIcon } from '@assets/icons';
import { Button, Input, Tooltip } from '@components/index';
import Popper, { PopperPlacement } from '@components/popper/Popper';

import { SavedFilter } from './CollapsibleAssetItem';
import FilterContainer from './FilterContainer';

export interface FilterData {
  id: string | number;
  label: string;
}

export interface AttributeFilterProps {
  savedFilter: SavedFilter;
  setSavedFilters: Dispatch<SetStateAction<SavedFilter>>;
  categoryOptions: FilterData[];
  documentList?: FilterData[];
  onApplyFilter: Function;
  onClearFilter: Function;
  popperPlacement?: PopperPlacement;
}

const AttributeFilter: FC<AttributeFilterProps> = ({
  savedFilter,
  setSavedFilters,
  popperPlacement = 'left',
  documentList,
  categoryOptions,
  onApplyFilter,
  onClearFilter,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<{
    category: FilterData[];
    document: FilterData[];
  }>({ category: [], document: [] });
  const [searchKeyword, setSearchKeyword] = useState<string>('');

  useEffect(() => {
    const handleScroll = () => {
      setIsOpen(false);
    };
    const scrollContainer = document.getElementById(
      'asset-table-scrollable-container',
    );

    if (isOpen && scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isOpen]);

  const handleSearch = useCallback(
    debounce((attributeName: string) => {
      setSearchKeyword(attributeName);
    }, 500),
    [],
  );

  const filterCount = savedFilter.category.length + savedFilter.document.length;

  const onCategoryOptionSelection = (categoryData: FilterData) => {
    setSelectedOptions(prev => {
      const isAlreadySelected = prev.category.some(
        item => item.id === categoryData.id,
      );

      const updatedDocument = isAlreadySelected
        ? prev.category.filter(item => item.id !== categoryData.id)
        : [...prev.category, categoryData];

      return { ...prev, category: updatedDocument };
    });
  };

  const onDocumentOptionSelection = (documentData: FilterData) => {
    setSelectedOptions(prev => {
      const isAlreadySelected = prev.document.some(
        item => item.id === documentData.id,
      );

      const updatedDocument = isAlreadySelected
        ? prev.document.filter(item => item.id !== documentData.id)
        : [...prev.document, documentData];

      return { ...prev, document: updatedDocument };
    });
  };

  const onCategoryReset = () => {
    setSelectedOptions(prev => ({ ...prev, category: [] }));
  };

  const handleApply = () => {
    setSavedFilters(selectedOptions);
    onApplyFilter(selectedOptions);
    setIsOpen(false);
  };

  const handleClear = () => {
    setSavedFilters({ category: [], document: [] });
    setIsOpen(false);
    onClearFilter();
  };

  const onDocumentReset = () =>
    setSelectedOptions(prev => ({ ...prev, document: [] }));

  const filteredCategoryOptions = searchKeyword
    ? categoryOptions.filter(option =>
        option?.label?.toLowerCase().includes(searchKeyword.toLowerCase()),
      )
    : categoryOptions || [];

  const filteredDocumentList =
    searchKeyword && documentList
      ? documentList.filter(option =>
          option?.label?.toLowerCase().includes(searchKeyword.toLowerCase()),
        )
      : documentList || [];

  const onCategorySelectAll = () =>
    setSelectedOptions(prev => ({
      ...prev,
      category: filteredCategoryOptions,
    }));

  const onDocumentSelectAll = () =>
    setSelectedOptions(prev => ({
      ...prev,
      document: filteredDocumentList,
    }));

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === ' ' || e.code === 'Space') {
      e.stopPropagation();
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    handleSearch(e.target?.value ?? '');
  };

  const RefComponent = () => (
    <>
      <span
        className="m-2 flex relative"
        onClick={() => {
          setSelectedOptions(savedFilter);
          setSearchKeyword('');
          setIsOpen(true);
        }}
      >
        <Tooltip
          RefComponent={<FilterIcon className="h-8 w-8 cursor-pointer" />}
          tooltipText="Use this filter to refine your document and attribute search."
          placement="top"
        />
      </span>
      {filterCount > 0 && (
        <span className="absolute inline-flex items-center justify-center bg-primary border-primary text-white rounded-xl px-2 py-0.5 !top-1 right-32 text-sm">
          {filterCount}
        </span>
      )}
    </>
  );

  return (
    <Popper
      placement={popperPlacement as PopperPlacement}
      PopComponent={({ popperElRef, setPopperElement }: any) => (
        <Transition
          afterLeave={() => {
            setPopperElement(null);
          }}
          as={Fragment}
          beforeEnter={() => {
            setPopperElement(popperElRef.current);
          }}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={isOpen}
        >
          <div className="flex flex-col border border-gray-2 bg-white rounded shadow-lg p-3 w-[400px]">
            <div className="flex justify-between items-center">
              <span className="text-black font-bold text-base">Filter</span>
              <Cross
                className="w-4 h-4 cursor-pointer"
                onClick={() => setIsOpen(false)}
              />
            </div>
            <span className="flex-grow overflow-auto">
              <div className="py-2">
                <Input
                  placeholder="Search"
                  onKeyDown={handleOnKeyDown}
                  onChange={handleOnChange}
                  withSearchIcon
                />
              </div>
              <FilterContainer
                filterDatas={filteredCategoryOptions}
                header="Category"
                onSelectOption={onCategoryOptionSelection}
                selectedData={selectedOptions.category}
                onResetAll={onCategoryReset}
                onSelectAll={onCategorySelectAll}
              />
              <FilterContainer
                filterDatas={filteredDocumentList}
                header="Documents"
                onSelectOption={onDocumentOptionSelection}
                selectedData={selectedOptions.document}
                onResetAll={onDocumentReset}
                onSelectAll={onDocumentSelectAll}
              />
            </span>
            <div className="bg-white p-2 mt-2 space-x-2 flex">
              <Button
                label="Clear"
                classOverride="font-bold !text-sm !text-black !w-1/2 !h-12"
                variant="text"
                onClick={handleClear}
              />
              <Button
                label="Apply"
                isDisabled={
                  !filteredDocumentList.length &&
                  !filteredCategoryOptions.length
                }
                classOverride="font-bold !w-1/2"
                variant="contained"
                onClick={handleApply}
              />
            </div>
          </div>
        </Transition>
      )}
      RefComponent={() => <RefComponent />}
    />
  );
};

export default AttributeFilter;
