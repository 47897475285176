import { FC, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { FolderEmpty } from '@assets/icons';
import { MoreOptions, Spinner } from '@components/index';
import { ASSET_INFO } from '@pages/home-page/constants';
import { AssetType } from '@pages/home-page/enums';
import { DataItem, GroupedKnowledgeItems } from '@pages/home-page/types';
import { isValidImage } from '@pages/home-page/utils';
import { workspace } from '@store/atoms/workspace';

import DocumentItems from './document-items/DocumentItems';
import ImagePreviewModal from './ImagePreviewModal';
import KnowledgeAttributeItems from './knowledge-attributes/KnowledgeAttributeItems';

interface AssetDataItemsProps {
  dataItems: DataItem[];
  isLoading?: boolean;
  assetType: string;
  imageClassName: string;
  handleDelete: (id: number, assetType: AssetType) => void;
  handleEdit?: (item: DataItem, assetType: AssetType) => void;
  knowledgeDataItems?: GroupedKnowledgeItems;
  isFilteredKnowledgeDataItems?: boolean;
}

interface ContentCellProps {
  content: React.ReactNode;
  className?: string;
}

const ContentCell: React.FC<ContentCellProps> = ({
  content,
  className = '',
}) => {
  return (
    <div
      className={`text-sm text-ellipsis overflow-hidden whitespace-normal text-justify ${className}`}
      style={{ wordBreak: 'break-word' }}
    >
      {content}
    </div>
  );
};

const AssetDataItems: FC<AssetDataItemsProps> = ({
  dataItems,
  isLoading,
  imageClassName,
  assetType,
  handleDelete,
  handleEdit,
  knowledgeDataItems,
  isFilteredKnowledgeDataItems,
}) => {
  const currentWorkspace = useRecoilValue(workspace);

  const [previewItem, setPreviewItem] = useState<DataItem | null>(null);

  const renderDetailItem = (item: DataItem) => {
    if ([AssetType.LOGO, AssetType.PRODUCT].includes(item.assetType)) {
      if (item?.url && isValidImage(item.url))
        return (
          <img
            src={item?.url}
            alt={`${item.assetType}_${item.id}`}
            className={`${imageClassName} max-h-[50px] max-w-[50px]`}
          />
        );
    }

    if (item.assetType === AssetType.COLORS) {
      return (
        <div className="flex items-center border w-fit border-gray-2 px-2 py-1 rounded">
          <div
            className="w-6 h-6 rounded-full m-1.5 drop-shadow mr-2.5 border"
            style={{ background: item.details }}
          />
          {item.details}
        </div>
      );
    }
  };

  if (isLoading) {
    return (
      <tr>
        <td colSpan={4} className="text-center pb-3">
          <Spinner />
        </td>
      </tr>
    );
  }

  if (
    dataItems.length === 0 &&
    assetType !== ASSET_INFO[AssetType.KNOWLEDGE].name
  ) {
    const formattedAssetType = assetType.slice(0, -1).toLowerCase();

    return (
      <tr>
        <td colSpan={4} className="text-center py-8">
          <div className="flex flex-col items-center justify-center gap-1">
            <FolderEmpty />
            <p className="text-sm text-dark-3 font-semibold">File Empty</p>
            <p className="text-sm text-dark-3">
              {`Seems like no ${formattedAssetType} has been added to the library`}
            </p>
          </div>
        </td>
      </tr>
    );
  }

  if (assetType === ASSET_INFO[AssetType.KNOWLEDGE].name)
    return (
      <KnowledgeAttributeItems
        knowledgeDataItems={knowledgeDataItems as GroupedKnowledgeItems}
        workspaceId={currentWorkspace.id}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        isFiltered={isFilteredKnowledgeDataItems}
      />
    );

  if (assetType === ASSET_INFO[AssetType.RAW_FILE].name)
    return (
      <DocumentItems
        dataItems={dataItems}
        workspaceId={currentWorkspace?.id}
        handleDelete={handleDelete}
      />
    );

  return (
    <>
      {dataItems.map(item => {
        const showPreview =
          (item.assetType === AssetType.LOGO ||
            item.assetType === AssetType.PRODUCT) &&
          isValidImage(item?.url as string);

        const showEdit =
          item.assetType !== AssetType.FONT &&
          item.parentClientId === currentWorkspace.id;

        return (
          <tr className="w-full" key={item.id}>
            <td className="pl-5 py-2 align-center">
              <div className="flex flex-row gap-4 items-center text-sm">
                <ContentCell content={item?.name} className="flex-grow" />
                <div className="flex-shrink-0">{renderDetailItem(item)}</div>
              </div>
            </td>
            <td className="py-2 pl-10 pr-2 align-center">
              <ContentCell content={item?.parentClientName} />
            </td>
            <td className="py-2 pl-1 pr-2 align-center">
              <ContentCell content={item?.createdBy} />
            </td>
            <td className="py-2 pr-3 align-center">
              <div className="flex items-center justify-between">
                <ContentCell content={item?.createdAt || '-'} />
                <MoreOptions
                  classOverride="!items-center"
                  onClickDelete={() => handleDelete(item.id, item.assetType)}
                  {...(showEdit && {
                    onClickEdit: () => handleEdit?.(item, item.assetType),
                  })}
                  {...(showPreview && {
                    onClickPreview: () => setPreviewItem(item),
                  })}
                />
              </div>
            </td>
          </tr>
        );
      })}
      {previewItem && (
        <ImagePreviewModal
          isOpen={!!previewItem}
          onClose={() => setPreviewItem(null)}
          fileName={previewItem?.name as string}
          imageUrl={previewItem?.url as string}
          externalAssetId={previewItem?.externalAssetId}
        />
      )}
    </>
  );
};
export default AssetDataItems;
